@import "../../assets/styles/helper/variables";
@import "../../assets/styles/helper/mixins";

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  min-height: max(77vh, rem(520));
}

.arrowBack {
  display: flex;
  justify-content: center;
  font-size: rem(14);
  line-height: rem(19);
  color: #744ced;
  padding: rem(7);
  cursor: pointer;
  span {
    margin-left: rem(10);
  }
}

.form {
  &Wrap {
    // padding: max(19%, rem(68)) rem(8) rem(202);
    margin-bottom: rem(50);
    @media (max-width: $tableWidth) {
      padding-bottom: 0;
      margin: rem(8) 0;
      padding: 0 rem(8);
    }
    p {
      font-weight: 300;
      font-size: rem(18);
      line-height: 130%;
      color: #4a3c73;
      max-width: rem(486);
      &::first-letter {
        text-transform: capitalize;
      }
      &.extraLinks {
        @media (max-width: $tableWidth) {
          font-size: rem(15);
        }
      }
    }
    .mobileHide {
      @media (max-width: $tableWidth) {
        display: none;
      }
    }
    button {
      font-weight: 400;
      font-size: rem(18);
      line-height: rem(25);
      margin-left: rem(18);
      @media (max-width: $tableWidth) {
        font-size: rem(15);
        margin-left: 0;
      }
    }
  }
  &Logo {
    margin-bottom: rem(45);
    @media (max-width: $tableWidth) {
      margin-bottom: rem(68);
    }
  }
  &Block {
    padding: rem(50) 0 rem(15);
    @media (max-width: $tableWidth) {
      padding-top: rem(70);
    }
    input {
      width: 100%;
      max-width: rem(380);
      height: rem(48);
      border: none;
      outline: 1px solid #f1ecff;
      border-radius: 10px;
      padding: rem(15) rem(34) rem(15) rem(20);
      // margin-right: rem(14);
      font-weight: 300;
      font-size: rem(14);
      line-height: 130%;
      color: #1f1d1c;
      margin-bottom: rem(4);
      &::placeholder {
        font-weight: 300;
        font-size: rem(14);
        line-height: 130%;
        color: #a197bd;
      }
      &:focus {
        outline: 1px solid #744ced;
      }
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: rem(48);
      padding: rem(12) rem(24);
      background: $mainFontColorPuprple;
      border-radius: rem(100);
      font-size: rem(18);
      line-height: rem(25);
      color: #f6f9fa;
      margin-bottom: rem(20);
      &.regBtn {
        width: 100%;
        max-width: rem(302);
        margin-left: 0;
        &:disabled {
          background: #e6e7fa;
          color: #cdcadc;
        }
      }
      @media (max-width: $tableWidth) {
        width: 100%;
        height: rem(42);
        max-width: rem(380);
        margin-bottom: rem(39);
      }

      span {
        margin-right: rem(8);
      }
    }
  }
  &Input {
    display: flex;
    align-items: start;
    width: 100%;
    max-width: rem(533);
    @media (max-width: $tableWidth) {
      align-items: start;
      flex-direction: column;
    }
    &.error {
      input {
        outline: 1px solid #da1d20;
      }
    }
    p.errorMsg {
      min-height: rem(16);
      font-size: rem(12);
      line-height: 130%;
      color: #da1d20;
      margin-bottom: rem(4);
    }
    &.LogPhone {
      input {
        min-width: rem(390);
        @media (max-width: $tableWidth) {
          min-width: unset;
        }
      }
    }
    &.LogPass {
    }

    &.LogReg {
      max-width: rem(363);
      margin-bottom: rem(30);
      input {
        max-width: rem(302);
      }
      .inputWrap {
        max-width: rem(320);
      }
    }
  }
}
.inputWrap {
  width: 100%;
  max-width: rem(380);
  margin-right: rem(17);
  position: relative;
  .passwordIcon {
    position: absolute;
    right: rem(-30);
    top: rem(12);
    cursor: pointer;
  }
}

h1 {
  font-weight: 600;
  font-size: rem(28);
  line-height: 130%;
  color: #1f1d1c;
  margin-bottom: rem(19);
  @media (max-width: $tableWidth) {
    font-size: rem(21);
    margin-bottom: rem(21);
  }
}
.extraLinks {
  margin-bottom: rem(22);
}
