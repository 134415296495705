@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"),
    url("../../fonts/OpenSans/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"),
    url("../../fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"),
    url("../../fonts/OpenSans/OpenSans-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"),
    url("../../fonts/OpenSans/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"),
    url("../../fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
}
