/* Vars:
  ========================================================================== */

//Main
$mainFont: "OpenSans", sans-serif;
$mainFontColor: #1f1d1c;
$mainFontSize: 16px;

$mainFontColorPuprple: #744ced;

// Size
$mainFontWeight: 400;
$mainLineHeight: 140%;

$desktopWidth: 1480px;
$lgTableWidth: 992px;
$tableWidth: 768px;
$lgPhoneWidth: 576px;
$phoneWidth: 480px;
$smPhoneWidth: 375px;

// Main color
$bgColor: #ffffff;
